<template>
  <div>
    <div class="table-responsive d-none d-md-block">
      <table
        class="align-middle mb-0 table table-borderless table-striped table-hover"
      >
        <thead>
          <tr>
            <!-- <th class="text-center">#</th> -->
            <th>Liquidación</th>
            <th class="text-center">N° Legajo</th>
            <th class="text-center">Total</th>
            <th class="text-center">Status</th>
            <th class="text-center">Emitido</th>
            <th class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="recibo in recibos" :key="recibo.id">
            <!-- <td class="text-center" style="width: 78px">
                <div class="custom-checkbox custom-control">
                  <input
                    type="checkbox"
                    id="eCheckbox1"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="eCheckbox1"
                    >&nbsp;</label
                  >
                </div>
              </td> -->
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      {{ recibo.liquidacion.periodo }}
                      {{ recibo.liquidacion.tipo_liquidiacion }}
                    </div>
                    <div class="widget-subheading">
                      {{ recibo.liquidacion.institucion.razon_social }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="widget-content p-0">
                <div
                  class="widget-content-wrapper justify-content-center align-items-center"
                >
                  <div class="widget-content-left">
                    <div class="widget-heading text-secondary">
                      {{ recibo.numero_legajo }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="widget-content p-0">
                <div
                  class="widget-content-wrapper justify-content-center align-items-center"
                >
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <span class="text-success"
                        >${{ recibo.neto | numFormat("0,0.00") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div
                class="badge badge-warning"
                v-if="recibo.estado == 'pendiente'"
              >
                Pendiente
              </div>
              <div
                class="badge badge-success"
                v-if="recibo.estado == 'aceptado'"
              >
                Aceptado e impreso
              </div>
            </td>
            <td class="text-center">
              <template v-if="recibo.fecha_firma">
                {{ recibo.fecha_firma }}
              </template>
              <template v-else> - </template>
            </td>
            <td>
              <div class="d-flex justify-content-around align-items-center">
                <button
                  class="btn-icon btn-icon-right btn btn-outline-primary btn-sm align-items-center"
                  @click="verRecibo(recibo)"
                >
                  Previsualizar <i class="fa fa-search"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ul class="list-group list-group-flush my-3 d-md-none d-block">
      <li class="list-group-item" v-for="recibo in recibos" :key="recibo.id">
        <div class="widget-content p-0">
          <div class="widget-content-wrapper align-items-start">
            <div class="widget-content-left">
              <div class="widget-subheading">{{ recibo.empleador }}</div>
              <div class="widget-heading text-alternate opacity-9">
                  {{ recibo.liquidacion.tipo_liquidacion }} - 
                  {{ recibo.liquidacion.periodo }}
              </div>
            </div>
          <!-- </div>
          <div class="widget-content-wrapper"> -->
            <div class="widget-content-right">
              <div
                class="badge badge-warning"
                v-if="recibo.estado == 'pendiente'"
              >
                Pendiente
              </div>
              <div
                class="badge badge-success"
                v-if="recibo.estado == 'aceptado'"
              >
                Emitido
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-secondary"> Legajo: </span>{{ recibo.numero_legajo }}
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-secondary">Total:</span>
                <span class="text-success">${{ recibo.neto | numFormat('0,0.00') }}</span>
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-secondary">Fecha Emisión:</span>
                {{ recibo.fecha_firma | formatDate('d/m/Y') }}
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper mt-1">
            <div class="w-100">
              <button
                class="btn-icon btn-icon-right btn btn-outline-primary btn-sm align-items-center btn-block"
                title="Previsualizar"
                @click="verRecibo(recibo)"
              >
                <i class="fa fa-eye"></i> Previsualizar
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <recibo-preview v-if="show" @cerrar="cerrarModal()" :recibo="reciboSelected"></recibo-preview>
  </div>
</template>
<script>
import ReciboPreview from '@/views/recibos/ReciboPreview'
export default {
  name: "TablaRecibos",
  components:{
    ReciboPreview
  },
  data() {
    return {
      show: false,
      reciboSelected: null,
    };
  },
  props: {
    recibos: { type: Array, required: true },
  },
  methods:{
    verRecibo(recibo) {
      this.reciboSelected = { ...recibo };
      this.reciboSelected.conceptos = this.reciboSelected.conceptos.filter(
        (concepto) => concepto.importe > 0
      );

      this.show = true;
    },
    cerrarModal(){
      this.show=false;
      this.$emit("actualizar");
      this.reciboSelected = null;
    }
  }
};
</script>
<style>
.badge-warning {
  color: 414141;
}
</style>