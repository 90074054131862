<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title">Dercargue sus recibos de sueldo</h2>
      <p>Por favor seleccione el período que desea visualizar</p>
      <div class="row">
        <float-label
          placeholder="MES"
          label="MES:"
          clase="col-md-3 col-6"
          id="mes"
          classInput="form-control"
          :select="true"
          v-model="month"
        >
          <template slot="options">
            <option :value="null" selected>Seleccione mes</option>
            <option v-for="m in meses" :value="m" :key="m">
              {{ m }}
            </option>
          </template>
        </float-label>
        <float-label
          placeholder="AÑO"
          label="AÑO:"
          clase="col-md-3 col-6"
          id="año"
          classInput="form-control"
          :select="true"
          v-model="year"
        >
          <template slot="options">
            <option :value="null" selected>Seleccione año</option>
            <option v-for="n in años" :key="n" :value="n">
              {{ n }}
            </option>
          </template>
        </float-label>
        <div class="col-12 col-md-3">
          <button class="btn btn-primary btn-block btn-wide" @click="getRecibo">Buscar</button>
        </div>
      </div>
      <div class="w-100"></div>
      <TablaRecibos :recibos="recibos" @actualizar="getRecibo"/>
    </div>
  </div>
</template>
<script>
import FloatLabel from "@/components/layouts/FloatLabel";
import TablaRecibos from "@/components/TablaRecibos";
import Axios from 'axios'
export default {
  name: "Misrecibos",
  components: {
    FloatLabel,
    TablaRecibos,
  },
  data() {
    return {
      month: "",
      year: "",
      recibos: [],
    };
  },
  props: {
    titleCard: String,
    iconoTitulo: String,
  },
  computed: {
    años: function () {
      let array = [];
      for (let index = 2020; index <= 2035; index++) {
        array.push(index);
      }
      return array;
    },
    meses: function () {
      return [
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE",
      ];
    },
  },
  mounted(){
    this.año = this.$moment(this.fecha).format("YYYY");
    this.mes = this.$moment(this.fecha).format("MMMM").toUpperCase();
    this.year = this.$moment(this.fecha).format("YYYY");
    this.month = this.$moment(this.fecha).format("MMMM").toUpperCase();
  },
  methods:{
    getRecibo(){
      let params = {
        periodo : this.month+" "+this.year
      }
      Axios.get('mis-recibos', {params: {...params}})
      .then((r) => {
        this.recibos = r.data
      })
      .catch((e) => {
        console.log(e)
      })
    }
  }
};
</script>